const getEventPosition = (event = {}, facilityDivisions = []) => {
  const divisionsPercentage = 100 / facilityDivisions.length;
  const firstDivision = facilityDivisions
    .sort((a, b) => a.rank - b.rank)
    .findIndex(({ id }) => id === event.divisions[0].id);

  return {
    left: `${(firstDivision * divisionsPercentage).toFixed()}%`,
    width: `${event.divisions.length * divisionsPercentage.toFixed()}%`
  };
};

export default getEventPosition;
