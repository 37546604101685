import React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { SportsSoccer, InfoOutlined } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import randomColor from "randomcolor";
import getEventPosition from "../../utils/events";
import {
  pickTextColorBasedOnBgColorSimple,
  cellHeight,
  isNullOrEmpty
} from "../../utils/calendar";

const styles = {
  eventContainer: {
    position: "absolute",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5
  },
  matchIcon: {
    position: "absolute",
    right: 0,
    height: 15
  },
  infoIcon: {
    position: "absolute",
    top: 2,
    right: 0,
    height: 15
  },
  tooltip: {
    fontSize: "1em"
  }
};

export default withStyles(styles)(({ event, divisions, classes, ...props }) => {
  const isMatch = event.type === "match";
  const eventWithNotes = !isNullOrEmpty(event.eventNotes);
  const parsedStartDate = DateTime.fromISO(event.startDate);
  const duration = DateTime.fromISO(event.endDate)
    .diff(parsedStartDate, "hours")
    .toObject();

  const getEventName = () => {
    switch (event.type) {
      case "repeatableTrain":
      case "train":
        return event.team.club.shortName
          ? `${event.team.club.shortName} - ${event.team.name}`
          : event.team.name;
      case "match":
        return `${getTeamName("localTeam")} ${getTeamName("visitorTeam")}`;
      case "repeatableFree":
      case "free":
        return event.freeEventName;
      default:
        return "Evento sin nombre";
    }
  };

  const getTeamName = prop =>
    typeof event[prop] !== "object"
      ? event[prop]
      : event[prop].shortName || event[prop].name;

  const getEventBackgroundColor = () => {
    switch (event.type) {
      case "match":
        return event.localTeam.name
          ? event.localTeam.color
          : randomColor({ seed: event.localTeam });
      case "free":
        return randomColor({ seed: event.freeEventName });
      default:
        return event.team && event.team.color ? event.team.color : "#039BE5";
    }
  };

  const backgroundColor = getEventBackgroundColor();

  return (
    <div
      className={classes.eventContainer}
      style={{
        zIndex: event.type === "free" ? 10 : event.type === "match" ? 6 : 2,
        top:
          (parsedStartDate.hour + parsedStartDate.minute / 60 - 6) *
          cellHeight *
          2,
        height: cellHeight * duration.hours * 2,
        ...getEventPosition(event, divisions),
        backgroundColor,
        cursor: "pointer"
      }}
      {...props}
    >
      <Typography
        style={{
          fontSize: "0.810rem",
          overflowWrap: divisions.length > 1 && "break-word",
          wordBreak: divisions.length > 1 && "break-word",
          textAlign: "center",
          lineHeight: 1,
          color: pickTextColorBasedOnBgColorSimple(
            backgroundColor,
            "#FFF",
            "#000"
          ),
          ...(eventWithNotes || isMatch ? { marginRight: 16 } : {})
        }}
      >
        {isMatch && (
          <SportsSoccer
            className={classes.matchIcon}
            style={eventWithNotes ? { top: 18 } : { top: 2 }}
          />
        )}
        {eventWithNotes && (
          <Tooltip
            classes={classes}
            title={event.eventNotes}
            placement="top"
            PopperProps={{
              style: { fontSize: 15 }
            }}
          >
            <InfoOutlined className={classes.infoIcon} />
          </Tooltip>
        )}
        {getEventName()}
      </Typography>
    </div>
  );
});
